<template>
    <div class="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 class="text-3xl text-center text-gray-900">{{ $tc('forgot_your_password', 1) }}?</h1>
            <p class="mt-1 text-center text-gray-500">{{ $tc('forgot_your_password_description', 1) }}</p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form @submit.prevent="requestNewPassword">
                    <fieldset :disabled="isSubmitting" class="space-y-6">
                        <!-- <MessageBox v-if="errors.general" :title="errors.general" :messages="errors.messages" /> -->
                        <Input :label="$tc('email', 1)" type="email" id="email" v-model="email" :error="validationErrors.email" />

                        <SubmitButton class="w-full" :label="$tc('request_link', 1)" :submittingLabel="$tc('requesting_link', 1)" :isSubmitting="isSubmitting" />

                        <div class="text-sm text-center">
                            <router-link class="font-medium text-indigo-600 hover:text-indigo-500" :to="{ name: 'login' }">{{ $tc('back_to_login', 1) }}</router-link>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    // import { validateEmail } from '@/utils/validators';

    import AuthService from '../../services/AuthService';

    export default {
        mounted() {
            // this.email = this.$route.query.email;
            // this.$router.replace({ ...this.$router.currentRoute });
        },

        data() {
            return {
                email: '',
                validationErrors: {},
                isSubmitting: false,
            };
        },

        methods: {
            async requestNewPassword() {
                try {
                    await AuthService.forgotPassword(this.email);
                    console.log('mailed sent');
                } catch (error) {
                    console.log(error);
                }
                // try {
                //     this.validationErrors = {};
                //     if (!this.user.email) this.validationErrors.email = this.$tc('fill_in_your_email', 1);
                //     else if (!validateEmail(this.user.email)) this.validationErrors.email = this.$tc('invalid_email', 1);
                //     if (Object.keys(this.validationErrors).length === 0) {
                //         // await this.$store.dispatch('auth/login', this.user);
                //     }
                // } catch (error) {
                //     if (error.response.status == 422) {
                //         this.errors = {
                //             general: (this.errors.general = `${this.$tc('unable_to_login', 1)}<br /> ${this.$tc('fix_and_retry', 1)}`),
                //             messages: Object.values(error.response.data.errors).flat(),
                //         };
                //     } else if (error.response.data.message) {
                //         this.errors = {
                //             general: error.response.data.message,
                //         };
                //     } else {
                //         this.errors = {
                //             general: `${this.$tc('unknown_error', 1)}<br /> ${this.$tc('please_try_agin', 1)}`,
                //         };
                //     }
                // }
            },
        },
    };
</script>
